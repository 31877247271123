<template>
  <div class="order">
    <div class="order-title">
      <img src="../../assets/user/order-title-icon.png" alt="" />
      <span>{{ $t('common.My_order') }}</span>
    </div>
    <div class="order-content">
      <div class="order-statistics">
        <div class="order-statistics-item" @click="allorder">
          <div class="order-statistics-item-image">
            <img src="../../assets/user/all-orders.png" />
            <div class="youshang" v-if="order != 0">
              {{ order }}
            </div>
          </div>
          <span class="order-statistics-item-text">{{ $t('common.Full_order') }}</span>
        </div>
        <div class="order-statistics-item" @click="daifu">
          <div class="order-statistics-item-image">
            <img src="../../assets/user/to-be-paid.png" />
            <div class="youshang" v-if="fukuan != 0">
              {{ fukuan }}
            </div>
          </div>
          <span class="order-statistics-item-text">{{ $t('common.due') }}</span>
        </div>

        <div class="order-statistics-item" @click="daifahuo">
          <div class="order-statistics-item-image">
            <img src="../../assets/user/fahuo.png" />
            <div class="youshang" v-if="fahuo != 0">
              {{ fahuo }}
            </div>
          </div>
          <span class="order-statistics-item-text">{{ $t('common.Goods_waiting_to_be_sent') }}</span>
        </div>

        <div class="order-statistics-item" @click="daishou">
          <div class="order-statistics-item-image">
            <img src="../../assets/user/to-be-received.png" />
            <div class="youshang" v-if="shouhuo != 0">
              {{ shouhuo }}
            </div>
          </div>
          <span class="order-statistics-item-text">{{ $t('common.Wait_to_be_received') }}</span>
        </div>

        <div class="order-statistics-item" @click="daiping">
          <div class="order-statistics-item-image">
            <img src="../../assets/user/to-be-evaluated.png" />
            <div class="youshang" v-if="pingjia != 0">
              {{ pingjia }}
            </div>
          </div>
          <span class="order-statistics-item-text">{{ $t('common.Remain_to_be_evaluated') }}</span>
        </div>

        <!--        <div class="order-statistics-item" @click="shouhou">-->
        <!--          <div class="order-statistics-item-image">-->
        <!--            <img src="../../assets/user/return-exchange.png" />-->
        <!--            <div class="youshang" v-if="tuihuan != 0">-->
        <!--              {{ tuihuan }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <span class="order-statistics-item-text">退还/售后</span>-->
        <!--        </div>-->
      </div>

      <!-- <div class="order-list">
        <div class="order-list-item">
          <div class="item-img">
            <img src="../../assets/user/tmp/commodity-001.png" />
          </div>
          <div class="item-text"></div>
          <div class="item-button"></div>
        </div>

        <div class="order-list-item">
          <div class="item-img">
            <img src="../../assets/user/tmp/commodity-002.png" />
          </div>
          <div class="item-text"></div>
          <div class="item-button"></div>
        </div>

        <div class="order-list-item">
          <div class="item-img">
            <img src="../../assets/user/tmp/commodity-003.png" />
          </div>
          <div class="item-text"></div>
          <div class="item-button"></div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      order: '0',
      fukuan: '0',
      shouhuo: '0',
      pingjia: '0',
      tuihuan: '0',
      fahuo: '0',
    };
  },
  created() {
    this.getorder();
    this.getfukuan();
    this.getshouhuo();
    this.getpingjia();
    this.getfahuo();
    // this.gettuihuan();
  },
  methods: {
    getorder() {
      get('api/orderbasic/getOrderByMemberId').then((res) => {
        this.order = res.data.data.total;
      });
    },
    getfukuan() {
      get('api/orderbasic/getOrderByMemberId?orderStatus=0').then((res) => {
        this.fukuan = res.data.data.total;
      });
    },
    getshouhuo() {
      get('api/orderbasic/getOrderByMemberId?orderStatus=2').then((res) => {
        this.shouhuo = res.data.data.total;
      });
    },
    getfahuo() {
      get('api/orderbasic/getOrderByMemberId?orderStatus=1').then((res) => {
        this.fahuo = res.data.data.total;
      });
    },
    getpingjia() {
      get(
        'api/orderbasic/getOrderPingJiaByMemberId?orderStatus=3&commentStatus=0'
      ).then((res) => {
        this.pingjia = res.data.data.total;
      });
    },
    gettuihuan(e) {
      get('api/orderreturn/getapplyorderlist?status=0').then((res) => {
        this.tuihuan = res.data.data.total;
      });
    },
    daifahuo() {
      this.$router.push({
        name: 'Fahuo',
      });
    },
    daifu() {
      this.$router.push({
        name: 'Fukuan',
      });
    },

    daishou() {
      this.$router.push({
        name: 'Shouhuo',
      });
    },
    daiping() {
      this.$router.push({
        name: 'Ping',
      });
    },
    shouhou() {
      this.$router.push({
        name: 'Mingxi',
      });
    },
    allorder() {
      this.$router.push({
        name: 'Dingdan',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  height: auto;
  width: 700px;
  background: #fff;

  &-title {
    height: 35px;
    padding: 5px;
    padding-left: 20px;
    color: #606060;
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    > span {
      margin-left: 10px;
    }
    img {
      width: 19px;
      height: 20px;
    }
  }

  &-content {
    width: 100%;
    padding-top: 1px;
    margin-bottom: 20px;
    padding-bottom: 1px;
  }

  &-statistics {
    margin: 44px 0px;
    padding-bottom: 1px;

    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, 140px);
    grid-gap: 10px 0px;

    &-item {
      width: 90px;
      text-align: center;
      margin: 0 auto;
      cursor: pointer;

      &-image {
        cursor: pointer;

        // display: inline-block;
        width: 45px;
        height: 45px;
        position: relative;
        margin: 0px auto;

        > img {
          width: 100%;
          height: 100%;
        }
      }
      .youshang {
        width: 20px;
        height: 20px;
        background: red;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        position: absolute;
        top: -10px;
        right: -10px;
      }

      &-text {
        display: inline-block;
        margin-top: 10px;
        padding-left: -9px;
        font-size: 14px;
        font-weight: 600;
        color: #333333;
      }

      &-corner {
        display: inline-block;
        position: absolute;
        top: -9px;
        right: -12px;
        height: 25px;
        width: 25px;
        font-size: 12px;
      }
    }
  }

  &-list {
    width: calc(100% - 60px);
    height: auto;
    padding: 0px 30px;
    background: #fff;
    &-item {
      width: calc(100% - 18px);
      height: 102px;
      padding: 9px 0px;
      border-top: 2px solid #eeeeee;
      .item-img {
        width: 100px;
        height: 100px;
        padding: 2px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .item-text {
        height: 50px;
      }
      .item-button {
        height: 50px;
      }
    }
  }
}
</style>
